<template>
  <div>
    <v-form>
      <p class="mb-3">
        Examine the
        <chemical-latex content="^1H" />
        spectrum of compound C.
      </p>
      <!-- What kind of question is this, even? -->
      <p class="mb-3">
        There is a table summarizing the spectral data. Using that data, assign each peak to the
        type of alkyl group.
      </p>
      <v-simple-table class="mb-3">
        <tr>
          <th></th>
          <th style="text-align: left">ppm</th>
          <th style="text-align: left">Integration</th>
          <th style="text-align: left">Splitting</th>
        </tr>
        <tr v-for="option in tableOptions" :key="option">
          <td v-for="part in option" :key="part">{{ part }}</td>
        </tr>
      </v-simple-table>
      <p>
        Alkyl groups:
        <v-text-field
          v-model="inputs.input1"
          class="mb-5"
          style="display: inline-block; width: 15em"
        />
      </p>

      <p class="mb-2">
        Based on the IR spectrum you determined that there will be an alcohol functional group. What
        signal in the
        <chemical-latex content="^1H" />
        spectrum is due to that
        <chemical-latex content="OH?" />
      </p>
      <v-radio-group v-model="inputs.input2" class="ml-6 mb-5" :disabled="!allowEditing">
        <v-radio
          v-for="option in radioOptions"
          :key="option.text"
          :value="option.value"
          class="my-1"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-2">
        Based on the carbon spectrum you know that the molecule has a high degree of symmetry. Yet
        compound B has only one
        <chemical-latex content="O." />
        Therefore the
        <chemical-latex content="OH" />
        group and the carbon that it is attached to have to belong in the plane of symmetry. Which
        <chemical-latex content="^1H" />
        signal is due to the hydrogen that is on the same carbon as the
        <chemical-latex content="OH" />
        group?
      </p>
      <v-radio-group v-model="inputs.input3" class="ml-6 mb-5" :disabled="!allowEditing">
        <v-radio
          v-for="option in radioOptions"
          :key="option.text"
          :value="option.value"
          class="my-1"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-2">
        Examine signal D. It has an integration of 12. How many equivalent methyl (
        <chemical-latex content="CH3" />
        ) groups are there in compound B?
      </p>
      <calculation-input
        v-model="inputs.input4"
        class="mb-5"
        prepend-text="$\text{number of equivalent methyl groups}:$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        Now, you need to arrange the methyl groups in such a way that each of them is bound to a
        carbon that only has one proton on it.
      </p>
      <p class="mb-2">
        Hint: Calculate how many carbons and hydrogens you have not used yet. What is the name of
        this alkyl fragment?
      </p>
      <v-radio-group v-model="inputs.input5" class="ml-6 mb-5" :disabled="!allowEditing">
        <v-radio
          v-for="option in alkylOptionsShuffled"
          :key="option.text"
          :value="option.value"
          class="my-1"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import CalculationInput from '../inputs/CalculationInput.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'ChemUCI51LBA2M2Q6',
  components: {ChemicalLatex, StembleLatex, CalculationInput},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
      },
      tableOptions: [
        ['A', '3.60', '1 H', 'singlet'],
        ['B', '3.20', '1 H', 'triplet'],
        ['C', '1.90', '2 H', 'multiplet'],
        ['D', '0.90', '12 H', 'doublet'],
      ],
      radioOptions: [
        {text: 'A', value: 'a'},
        {text: 'B', value: 'b'},
        {text: 'C', value: 'c'},
        {text: 'D', value: 'd'},
      ],
      alkylOptions: [
        {text: 'ethyl group', value: 'ethyl'},
        {text: 'isopropyl group', value: 'iso'},
        {text: '${n}-$propyl group', value: 'n'},
        {text: '${tert}-$butyl group', value: 'tert'},
      ],
    };
  },
  computed: {
    seed() {
      return this.$gate.user?.id || 1;
    },
    alkylOptionsShuffled() {
      return seededShuffle(this.alkylOptions, this.seed);
    },
  },
};
</script>
